/**
 * 用户名
 * @param rule 验证规则（不含特殊字符）
 * @param value 需要验证的值
 * @param callback 回调函数
 */
export const validateUsername = (rule, value, callback) => {
  var reg = /^[a-zA-Z0-9_\u4e00-\u9fa5]{1,16}$/;
  if (value.length > 16) {
    callback(new Error('长度不能超过16个字符'));
  } else if (!reg.test(value)) {
    callback(new Error('仅支持大小写英文、中文和下划线_'));
  } else {
    callback();
  }
};

/**
 * 密码格式验证
 * @param rule 验证规则（不少于6位）
 * @param value 需要验证的值
 * @param callback 回调函数
 */
export const validatePassword = (rule, value, callback) => {
  if (value.length < 6) {
    callback(new Error('密码长度不得小于6位'));
  } else {
    callback();
  }
};

/**
 * 手机号码格式验证
 * @param rule 验证规则
 * @param value 需要验证的值
 * @param callback 回调函数
 */
export const validateMobile = (rule, value, callback) => {
  var reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
  if (!reg.test(value)) {
    callback(new Error('手机号格式错误'));
  } else {
    callback();
  }
};

/**
 * 手机号码格式验证
 * @param rule 验证规则
 * @param value 需要验证的值
 * @param callback 回调函数
 */
export const validateMobile2 = (rule, value, callback) => {
  // 可以为空
  if (typeof(value) == "undefined" || value.length == 0) callback();

  var reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
  if (!reg.test(value)) {
    callback(new Error('手机号格式错误'));
  } else {
    callback();
  }
};

/**
 * 身份证号码格式验证
 * @param rule 验证规则（是否满足18位）
 * @param value 需要验证的值
 * @param callback 回调函数
 */
export const validateIDCard = (rule, value, callback) => {
  const reg = /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
  if (!reg.test(value)) {
    callback(new Error('身份证号码格式错误'));
  } else {
    callback();
  }
};

/**
 * 数值校验
 * @param rule 验证规则（有效数字）
 * @param value 需要验证的值
 * @param callback 回调函数
 */
export const validateNumber = (rule, value, callback) => {
  // 模拟异步验证效果
  if (!Number.isInteger(+value)) {
      callback(new Error('请输入有效数字'));
  } else {
      callback();
  }
};

/**
 * 期限校验
 * @param rule 验证规则（小于1000）
 * @param value 需要验证的值
 * @param callback 回调函数
 */
export const validateQx = (rule, value, callback) => {
  // 模拟异步验证效果
  if (value>=1000) {
      callback(new Error('输入小于1000的数值'));
  } else {
      callback();
  }
};

/**
 * 电话号码验证
 * @param rule 验证规则
 * @param value 需要验证的值
 * @param callback 回调函数
 */
export const validateTel = (rule, value, callback) => {
  var reg1 = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
  var reg2 = /^((0\d{2,3})-)?(\d{7,8})(-(\d{3,4}))?$/;
  if (!reg1.test(value) && !reg2.test(value)) {
    callback(new Error('电话号码格式错误'));
  } else {
    callback();
  }
};

/**
 * 金额的检查
 * @param rule 验证规则
 * @param value 需要验证的值
 * @param callback 回调函数
 */
 export const validateMoney = (rule, value, callback) => {
  var reg = /((^[1-9]\d*)|^0)(\.\d{0,2}){0,1}$/;
  if (!reg.test(value)) {
    callback(new Error('金额输入格式错误'));
  } else {
    callback();
  }
};

/**
 * 小数
 * @param rule 验证规则
 * @param value 需要验证的值
 * @param callback 回调函数
 */
 export const validateXs = (rule, value, callback) => {
  var reg = /^(?:0\.\d+|[01](?:\.0)?)$/;
  if (!reg.test(value)) {
    callback(new Error('输入格式错误，只能输入不大于1的小数'));
  } else {
    callback();
  }
};

/**
 * 邮箱
 * @param rule 验证规则
 * @param value 需要验证的值
 * @param callback 回调函数
 */
 export const validateEmail = (rule, value, callback) => {
  // var reg = /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
  var reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (!reg.test(value)) {
    callback(new Error('输入格式错误，邮箱格式需要:XXXX@XX.XXX'));
  } else {
    callback();
  }
};