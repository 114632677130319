<style lang="less">
  @import "./change-email.less";
</style>

<template>
  <div>
    <Card class="change-pass">
      <p slot="title">
        <Icon type="key"></Icon>修改邮箱
      </p>
      <div>
        <Form ref="editPasswordForm" :model="editPasswordForm" :label-width="100" label-position="right"
          :rules="passwordValidate" style="width:450px">
          <FormItem label="原邮箱" prop="email">
            <Input type="text" v-model="editPasswordForm.email" readonly></Input>
          </FormItem>
          <FormItem label="新邮箱" prop="newEmail">
            <Input type="text" v-model="editPasswordForm.newEmail" :maxlength=30 placeholder="请输入正确的新邮箱"></Input>
          </FormItem>
          <FormItem>
            <Button type="primary" style="width: 100px;margin-right:5px" :loading="savePassLoading"
              @click="saveEditEmail">保存</Button>
            <Button @click="cancelEditEmail">取消</Button>
          </FormItem>
        </Form>
      </div>
    </Card>
  </div>
</template>

<script>
  import {
    changeEmail,
    userInfo,
  } from "@/api/index";
  import {
    validateEmail,
  } from "@/libs/validate";
  export default {
    name: "change_pass",
    components: {},
    data() {
      return {
        savePassLoading: false,
        editPasswordForm: {
          email: "",
          newEmail: "",
        },
        strength: "",
        passwordValidate: {
          newEmail: [
            {
              required: true,
              message: "请输入新邮箱地址",
              trigger: "blur"
            },{
            validator: validateEmail,
            trigger: "blur"
            }
          ],
        }
      };
    },
    methods: {
      init() {
        this.savePassLoading = true;
        userInfo().then(res => {
            this.savePassLoading = false;
            if (res.success) {
              this.editPasswordForm.email = res.result.email;
            }
          });
      },
      saveEditEmail() {
        if (this.editPasswordForm.email == this.editPasswordForm.newEmail) {
            this.$Message.warning("新旧邮箱都一样，有什么好改呢");
            return;
        }
        let params = {
          email: this.editPasswordForm.newEmail
        };
        this.$refs["editPasswordForm"].validate(valid => {
          if (valid) {
            this.savePassLoading = true;
            changeEmail(params).then(res => {
              this.savePassLoading = false;
              if (res.success) {
                this.$Modal.success({
                  title: "系统提示",
                  content: "修改邮箱成功",
                  onOk: () => {
                    this.cancelEditEmail();
                  }
                });
              }
            });
          }
        });
      },
      cancelEditEmail() {
        this.$store.commit("removeTag", "change_email");
        localStorage.pageOpenedList = JSON.stringify(
          this.$store.state.app.pageOpenedList
        );
        let lastPageName = "";
        let length = this.$store.state.app.pageOpenedList.length;
        if (length > 1) {
          lastPageName = this.$store.state.app.pageOpenedList[length - 1].name;
        } else {
          lastPageName = this.$store.state.app.pageOpenedList[0].name;
        }
        this.$router.push({
          name: lastPageName
        });
      }
    },
    mounted() {
      this.init();
    }
  };
</script>